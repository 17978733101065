'use client';

import React, { createContext, useCallback, useContext, useState } from 'react';

import { DisplayUser } from '@/lib/api';
import { API_BASE_URL } from '@/lib/common';

type UserInfoContextType = {
  userInfo: DisplayUser | null;
  reloadUserInfo: () => Promise<void>;
  fetchUserInfo: () => Promise<DisplayUser | null>;
};

const UserInfoContext = createContext<UserInfoContextType | undefined>(undefined);

type UserInfoProviderProps = {
  children: React.ReactNode;
};

export const UserInfoProvider: React.FC<UserInfoProviderProps> = ({ children }) => {
  const [userInfo, setUserInfo] = useState<DisplayUser | null>(null);

  const fetchUserInfo = useCallback(async (): Promise<DisplayUser | null> => {
    try {
      const authCheckUrl = `${API_BASE_URL}/user/info`;
      const response = await fetch(authCheckUrl);
      if (!response.ok) {
        setUserInfo(null);
        throw new Error('Failed to fetch user info');
      }
      const userDetails: DisplayUser = await response.json();
      setUserInfo(userDetails);
      return userDetails;
    } catch (error) {
      setUserInfo(null);
      console.error('Error reloading user details:', error);
      return null;
    }
  }, []);

  const reloadUserInfo = useCallback(async () => {
    await fetchUserInfo();
  }, [fetchUserInfo]);

  // Fetch user info on component mount
  React.useEffect(() => {
    fetchUserInfo();
  }, [fetchUserInfo]);

  return (
    <UserInfoContext.Provider value={{ userInfo, reloadUserInfo, fetchUserInfo }}>
      {children}
    </UserInfoContext.Provider>
  );
};

export const useUserInfo = (): UserInfoContextType => {
  const context = useContext(UserInfoContext);
  if (context === undefined) {
    throw new Error('useUserInfo must be used within a UserInfoProvider');
  }
  return context;
};
